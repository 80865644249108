@import "../../main.scss";
@import "../../theme.scss";

.countContainer {
	display: flex;
	flex-direction: column;
}

.wrapper {
	font-family: $font;
	@include layout(row, space-between, center);
	margin: 5% 7% 0 7%;
	.container {
		@include layout(column, center, center);
		width: 30rem;
		img {
			width: 28rem;
		}
		.fadeinimage {
			animation: fadeIn 5s;
			-webkit-animation: fadeIn 5s;
			-moz-animation: fadeIn 5s;
			-o-animation: fadeIn 5s;
			-ms-animation: fadeIn 5s;
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-moz-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-webkit-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-o-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-ms-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
		.info {
			@include layout(column, center, center);
			width: 70%;
			.button {
				@include layout(row, center, center);
			}
		}
	}
	:global {
		.ml15 {
			font-weight: 800;
			font-size: 3.8em;
			text-transform: uppercase;
			letter-spacing: 0.5em;
		}

		.ml15 .word {
			display: inline-block;
			line-height: 1em;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.wrapper {
		font-family: $font;
		width: 86%;
		min-height: 30vh;
		@include layout(row, space-between, center);
		margin: 5% 7% 0 7%;
		.container {
			@include layout(column, center, center);
			h1 {
				font-size: 1rem;
			}
			img {
				width: 18rem;
			}
			.fadeinimage {
				animation: fadeIn 5s;
				-webkit-animation: fadeIn 5s;
				-moz-animation: fadeIn 5s;
				-o-animation: fadeIn 5s;
				-ms-animation: fadeIn 5s;
			}
			@keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-moz-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-webkit-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-o-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-ms-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}
			.info {
				@include layout(column, center, center);
				width: 70%;
				.button {
					@include layout(row, center, center);
					h2 {
						font-size: 1rem;
					}
				}
			}
		}
		.svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

@media only screen and (max-width: 650px) {
	.flexBox {
		flex-direction: column;
	}
}

@media only screen and (max-width: 550px) {
	.flexBox {
		flex-direction: column;
		align-items: center;
	}

	.wrapper {
		font-family: $font;
		width: 86%;
		min-height: 50vh;
		@include layout(row, space-between, center);
		margin: 5% 7% 0 7%;
		.container {
			@include layout(column, center, center);
			width: 50vw;
			h1 {
				font-size: 1rem;
			}
			img {
				width: 13rem;
			}
			.fadeinimage {
				animation: fadeIn 5s;
				-webkit-animation: fadeIn 5s;
				-moz-animation: fadeIn 5s;
				-o-animation: fadeIn 5s;
				-ms-animation: fadeIn 5s;
			}
			@keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-moz-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-webkit-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-o-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@-ms-keyframes fadeIn {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}
			.info {
				@include layout(column, center, center);
				width: 70%;
				.button {
					@include layout(row, center, center);
					h2 {
						font-size: 1rem;
					}
				}
			}
		}
		.svg,
		.svg1 {
			display: none;
		}
		//.countersWrapper {
		//  display: none;
		//}
	}
}
