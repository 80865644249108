@import "../../../../../../main.scss";
@import "../../../../../../theme.scss";

.ListItem {
	text-align: left;
	//border: 1px solid lightgray !important;
	border-radius: 10px;
	margin-bottom: 1em;
	margin-top: 1em;

	li {
		padding-left: 0 !important;
		display: block !important;

		div {
			padding-bottom: 0 !important;
		}
	}
}

.buttonsContainer {
	display: flex;
	padding-top: 1%;
}

.alignmentContainer {
	display: flex;
	align-items: center;
	margin-right: 5%;
}

.alignmentContainer > * {
	justify-content: flex-start;
}

@media only screen and (max-width: 1100px) {
	.listItemMeta > div {
		width: 100% !important;
	}

	.listItemMeta {
		display: block !important;
	}
}

.actionsContainer > div {
	margin-right: 30px;
}

.actionsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: fit-content;
	text-align: left;
}
