@import "../../main.scss";
@import "../../theme.scss";

:global .ant-tabs-tab {
	padding: 0 !important;
}

@media screen and (max-width: 1000px) {
	.displayFlex > div > div > div {
		display: flex;
		align-items: center !important;
		justify-content: space-between !important;
		width: 80vw !important;
		flex-direction: column !important;
	}
}

.ListItem {
	text-align: left;
	//border: 1px solid lightgray !important;
	border-radius: 10px;
	margin-bottom: 1em;
	margin-top: 1em;

	li {
		padding-left: 0 !important;
		display: block !important;

		div {
			padding-bottom: 0 !important;
		}
	}
}

.antSelect > div {
	height: 35.5px !important;
}

.antSelect:hover,
.antSelect > div:hover,
.antSelect > div:focus,
.antSelect > div:focus-visible .antSelect > div:active {
	border-color: #d9d9d9 !important;
}

.searchBox {
	margin-top: 24px;
	margin-bottom: 10px;
	width: 100% !important;
}

.actionsContainer > div {
	margin-right: 30px;
}

.actionsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: fit-content;
	text-align: left;
}

.customRow {
	width: 7em !important;
}

.customCol {
	display: inline-flex !important;
	justify-content: center;
	align-items: center;
}

.flexParagraph {
	display: flex;
	justify-content: center;
	text-align: center;
	margin-bottom: 0;
}

.buttonsContainer {
	display: flex;
	padding-top: 1%;
}

.sort {
	margin-bottom: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100% !important;
}

.sortBtn {
	background-color: transparent !important;
	color: black !important;
	border-color: transparent;
	border: none !important;
	box-shadow: none !important;
	margin-right: 0 !important;
}

.customInput {
	width: 100vw !important;
}

.alignmentContainer {
	display: flex;
	align-items: center;
	margin-right: 5%;
}

.alignmentContainer > * {
	justify-content: flex-start;
}

.rejectedStatus {
	color: darkred;
}

.filterButton {
	display: flex;
	align-items: center;
}

@media only screen and (min-width: 1101px) {
	.filterButton {
		display: none !important;
	}
}

@media only screen and (max-width: 1100px) {
	.filters,
	.searchBox {
		display: none !important;
	}

	.buttonsContainer > * {
		display: block;
	}

	.filterButton {
		padding-left: 5%;
	}

	.listItemMeta > div {
		width: 100% !important;
	}

	.listItemMeta {
		display: block !important;
	}
}

@media only screen and (max-width: 520px) {
	.alignmentContainer {
		width: 100%;
	}
}

:global .ant-list-vertical .ant-list-item-meta {
	margin-bottom: 0 !important;
}

:global .ant-list-vertical .ant-list-item-meta-title {
	margin-bottom: 0 !important;
	font-weight: bold;
}

:global .ant-list-lg .ant-list-item {
	padding: 4px 24px !important;
}
