@import "../../main.scss";
@import "../../theme.scss";

.wrapper {
	height: auto;

	.rowContainer {
		display: inline-flex;
		width: 100%;
		justify-content: space-between;
	}

	.divider {
		width: 10%;
	}

	.customInput,
	.customInput:hover,
	.customInput:active,
	.customInput:focus {
		margin-top: 0 !important;
		width: 100%;
		margin-right: 2%;
	}

	.save {
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.changeImage {
		width: 35px;
		height: 35px;
		border-radius: 100%;
		border: none;
		outline: none;
		color: white;
		background-color: rgb(58 58 60 / 1);
		margin-left: -35%;
		margin-top: 60%;
		font-size: 21px;
	}

	.changeImage:hover {
		opacity: 0.5;
	}

	.usersTable {
		width: 100%;
	}

	.modalBtnContainer {
		display: inline-flex !important;
		margin-right: 3%;
	}

	.modalContainer > div {
		padding-bottom: 0 !important;
	}

	.container {
		@include layout(row, flex-start, space-between);
		font-family: $font;
		width: 100%;
		margin: 5% 5% 0 0;

		.tableofContents {
			@include layout(column, flex-start, flex-start);
			text-align: left;
			width: 30%;
			margin-right: 5%;
			a {
				padding-bottom: 20%;
			}
			.firstColumn {
				@include layout(row, flex-start, flex-start);
				background-color: rgb(255, 255, 255);
				margin-bottom: 10%;
				margin-left: auto;
				margin-right: auto;
				.image,
				.image:hover {
					width: 100%;
					text-align: center;
					img {
						width: 160px;
						min-height: 160px;
						border-radius: 100%;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}

		.tableElements {
			@include layout(column, space-between, flex-start);
			width: 100%;
			.container {
				@include layout(column, flex-start, flex-start);
				text-align: left;
				h2 {
					width: 80%;
					font-weight: bold;
					font-size: 1.1rem;
					margin-bottom: 5%;
				}
				.inputs {
					width: 100%;
					border-bottom: 0.3px solid $lineColor;
					padding: 0 0 5% 0;
					h3 {
						font-size: 0.9rem;
						width: 25%;
						padding: 2% 0 1% 0;
						margin-right: 2%;
					}
					h6 {
						font-size: 0.9rem;
						width: 60%;
						padding: 1% 0;
					}
					.inputColumn {
						@include layout(column, flex-start, flex-start);
						width: 100%;
						p {
							color: red;
							font-size: 0.9rem;
							padding: 1% 0;
						}
						.inputRow {
							width: 100%;
							margin-top: 1%;
							@include layout(row, space-between, flex-start);
							h4 {
								color: $primaryColor;
								font-size: 0.9rem;
								padding: 1% 0;
								font-weight: bold;
								opacity: 1 !important;
							}
						}
					}
					.input {
						@include layout(row, flex-start, flex-start);
						width: 100%;
						textarea,
						input,
						.customAutocomplete,
						.styledInput {
							border: 0.5px solid $forthColor;
							outline: none;
							resize: none;
							box-shadow: none;
							margin-top: 1%;
							margin-bottom: 2%;
							border-radius: 4px !important;
							width: 19rem;
							height: 30px;
							padding: 2% 0 1% 1.5%;
							font-family: "Montserrat";
							font-size: 14px;
							color: rgba(0, 0, 0, 0.85);
							&:hover,
							&:focus,
							&:active {
								border: 0.5px solid $forthColor;
								outline: none;
								resize: none;
								box-shadow: none;
								border-radius: 4px !important;
							}
						}
					}
					.inputAutocomplete {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: flex-start;
						width: 100%;

						.customAutocomplete {
							outline: none;
							resize: none;
							box-shadow: none;
							margin-top: 1%;
							border-radius: 4px !important;
							color: $black;
							width: 19rem;
							&:hover,
							&:focus,
							&:active {
								border: 0.5px solid $forthColor;
								outline: none;
								resize: none;
								box-shadow: none;
								border-radius: 4px !important;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 811px) {
	.saveButtons {
		display: none;
	}
}

@media only screen and (max-width: 810px) {
	.saveButtons {
		display: none;
	}

	.wrapper {
		height: auto;

		.subtitle {
			@include layout(row, flex-start, flex-start);
			width: 68%;
			margin: 3% 5% 2% 5%;
			border-bottom: 1px solid;
			border-color: $lineColor;
			h5 {
				font-size: 1.3rem;
				font-family: $font;
				font-weight: bold;
			}
		}
		.container {
			@include layout(column, flex-start, space-between);
			font-family: $font;
			width: 90%;
			margin: 0 5% 5% 5%;

			.tableofContents {
				@include layout(column, flex-start, flex-start);
				text-align: left;
				width: 100%;
				margin-right: 5%;
				a {
					padding-bottom: 20%;
				}
				.firstColumn {
					@include layout(row, flex-start, flex-start);
					background-color: rgb(255, 255, 255);
					padding-right: 5%;
					margin-bottom: 10%;
					.image {
						width: 100%;
						img {
							width: 150px;
							min-height: 13vh;
							margin: 0;
						}
						span {
							@include layout(row, center, flex-end);
							opacity: 0;
							border-radius: 5px;
							padding: 7%;
							margin: -23% 0% 0% 0%;
							width: 100%;
						}
					}
					.image:hover {
						width: 100%;
						img {
							width: 150px;
							min-height: 13vh;
							margin: 0;
						}
						span {
							@include layout(row, center, flex-end);
							opacity: 1;
							width: 100%;
							border-radius: 5px;
							font-weight: bold;
							margin: -23% 0% 0% 0%;
							z-index: 2;
							position: relative;
							padding: 7%;
							font-family: $font;
							color: white;
							background-color: rgba(black, 0.5);
						}
					}
				}
			}

			.tableElements {
				@include layout(column, space-between, flex-start);
				width: 100%;
				.container {
					@include layout(column, flex-start, flex-start);
					text-align: left;
					h2 {
						width: 80%;
						font-weight: bold;
						font-size: 1.1rem;
						margin-bottom: 5%;
					}
					.inputs {
						width: 100%;
						border-bottom: 0.3px solid $lineColor;
						padding: 0 0 5% 0;
						h3 {
							font-size: 0.9rem;
							width: 20%;
							padding: 2% 0 1% 0;
							margin-right: 2%;
						}
						h6 {
							font-size: 0.9rem;
							width: 60%;
							padding: 1% 0;
						}
						.inputColumn {
							@include layout(column, flex-start, flex-start);
							width: 100%;
							p {
								color: red;
								font-size: 0.9rem;
								padding: 1% 0;
							}
							.inputRow {
								width: 100%;
								margin-top: 1%;
								@include layout(row, space-between, flex-start);
								h4 {
									color: $primaryColor;
									font-size: 0.9rem;
									padding: 1% 0;
									font-weight: bold;
									opacity: 1 !important;
								}
							}
						}
						.input {
							@include layout(row, flex-start, flex-start);
							width: 100%;
							textarea,
							input {
								border: 0.5px solid $forthColor;
								outline: none;
								resize: none;
								box-shadow: none;
								margin-top: 1%;
								border-radius: 4px !important;
								color: $black;
								&:hover,
								&:focus,
								&:active {
									border: 0.5px solid $forthColor;
									outline: none;
									resize: none;
									box-shadow: none;
									border-radius: 4px !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
