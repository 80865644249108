@import "../../main.scss";
@import "../../theme.scss";

a,
a:active,
a:hover {
	color: $black;
}

.radioButtons {
	background: white !important;
	border-color: $primaryColor !important;
	border-radius: 4px !important;
}

.radioButtons::before {
	background-color: $primaryColor !important;
	height: 85% !important;
	top: 1px !important;
	left: 0px !important;
	width: 0.8px !important;
}

.radioButtons:hover {
	background: white !important;
	border-color: $thirdGreen !important;
	color: $thirdGreen !important;
	border-radius: 4px !important;
}

.questionBorder {
	border: 2px solid #376e6f;
	border-radius: 20px;
	padding: 15px;
}

.categoryLabel {
	font-size: x-large !important;
}

.saveBtn {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.delete {
	outline: none;
	background-color: transparent;
	border: none;
	float: right;
	&:hover {
		cursor: pointer;
	}
}

.container {
	@include layout(row, center, center);
	font-family: $font;
	margin: 5% 12% !important;
	display: block;

	.button {
		@include layout(row, flex-start, flex-start);
		h6 {
			text-align: center;
			color: $white;
			font-size: 0.9rem;
			font-weight: 100;
			margin: 2% 4% 2% 2%;
		}
		h6 span {
			background-color: $secondColor;
			border-radius: 5px;
			padding: 7px 13px;
		}
	}
	h5 {
		margin: 2% 0;
		font-size: 0.9rem;
	}

	.firstLine {
		@include layout(column, flex-start, flex-start);
		width: 100%;
		:global {
			.ant-row {
				width: 100% !important;
			}
		}
		.checkbox {
			@include layout(row, flex-start, flex-start);
		}
		textarea,
		.numberInput {
			border: 0.5px solid #d9d9d9;
			outline: none;
			resize: none;
			box-shadow: none;
			border-radius: 4px !important;
			color: rgb(0, 0, 0) !important;
			margin-bottom: 1%;
			width: 100%;
			height: 2.2rem;
			padding: 5px 5px 5px 1%;
			&:hover,
			&:focus,
			&:active {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
			}
		}

		.customSelect {
			text-align: left;
		}

		textarea::placeholder,
		.numberInput::placeholder {
			opacity: 0.25;
		}
	}
	.secondLine {
		margin-top: 5%;
		width: 100%;
		@include layout(row, space-between, flex-start);

		.firstColumn {
			@include layout(row, space-between, center);
			width: 250px;
		}
		.secondColumn {
			@include layout(row, flex-end, center);
		}
	}

	:global {
		.ant-picker,
		.ant-picker-range,
		.ant-picker-focused {
			border-color: #d9d9d9 !important;
			box-shadow: none !important;
			border-width: 1px !important;
			border-radius: 5px !important;
			padding: 10px !important;
			transition: none !important;
		}
		.ant-picker:hover,
		.ant-picker-range:hover,
		.ant-picker-focused:hover {
			border-color: #d9d9d9 !important;
			box-shadow: none !important;
			border-width: 1px !important;
			border-radius: 5px !important;
			transition: none !important;
		}
		.ant-picker-range .ant-picker-active-bar {
			bottom: -1px;
			height: 2px;
			margin-left: 11px;
			background: #d9d9d9 !important;
		}
		.ant-picker-cell
			.ant-picker-cell-in-view
			.ant-picker-cell-range-start
			.ant-picker-cell-range-end
			.ant-picker-cell-today
			.ant-picker-cell-selected {
			color: #fff;
			background: #1c3334;
			border: #1c3334;
		}

		.ant-select-selection-item {
			display: flex !important;
		}
	}
}

.ant-radio-button-inner:after {
	background: red !important;
}

.ant-radio-button-checked .ant-radio-button-inner,
.ant-radio-button:hover .ant-radio-button-inner,
.ant-radio-button-wrapper:hover,
.ant-radio-button-input:focus .ant-radio-button-inner {
	border-color: red !important;
}
