// @import '~antd/lib/style/themes/default.less';
// @import 'antd/dist/antd.min.css';
@import "./theme.scss";

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked {
	background: $primaryColor !important;
	border-color: $primaryColor !important;
	color: white !important;
	border-radius: 4px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
	background: $thirdGreen !important;
	border-color: $thirdGreen !important;
	color: white !important;
	border-radius: 4px !important;
}

.ant-radio-group .ant-radio-checked .ant-radio-inner,
.ant-radio-group .ant-radio:hover .ant-radio-inner,
.ant-radio-group .ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-group .ant-radio-input:focus .ant-radio-inner,
.ant-radio-inner:after {
	border-color: $primaryColor !important;
}
.ant-radio-group .ant-radio-inner:after {
	background-color: $primaryColor !important;
}

.ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
	border-color: $primaryColor !important;
	background-color: $primaryColor !important;
}

.ant-checkbox-group .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-group .ant-checkbox:hover .ant-checkbox-inner {
	border-color: $primaryColor !important;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.ant-tree .ant-tree-treenode {
	text-align: left !important;
}

.ant-switch-checked {
	background: $secondColor !important;
}

:global {
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
		color: #fff;
		background: $secondColor !important;
	}
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
}
