@import "../../theme.scss";

.ant-switch-checked {
	background: $secondColor !important;
}

td {
	text-align: center;

	.modalBtnContainer {
		display: inline-flex !important;
		margin-right: 3%;
	}
	.modalBtnContainer > div {
		padding-bottom: 0 !important;
	}
}
