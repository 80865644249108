@import "../../main.scss";
@import "../../theme.scss";

.button,
.button:active,
.button:focus {
	background-color: #456d6e;
	color: white;
	border-radius: 12px;
	border: #456d6e;
}

.button:hover {
	background-color: #1c3334;
	color: white;
	border-radius: 12px;
	outline: none;
	border: #1c3334;
}

.button:disabled,
.button:disabled:hover {
	cursor: auto;
	color: black;
	background-color: transparent;
}
