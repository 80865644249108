@import "../../main.scss";
@import "../../theme.scss";

.addActivity:hover,
.addActivity:focus {
	color: $primaryColor;
	border-color: $primaryColor;
}

a,
a:active,
a:hover {
	color: $black;
}

.deleteContainer {
	margin-top: 0.2rem;
	width: auto;
	font-size: 14px;
	&:hover {
		background-color: #e8e7e7;
	}
}

.input_container {
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
}

input {
	margin-right: 0.4rem;
}

.delete {
	outline: none;
	background-color: transparent;
	border: none;
	float: right;
	&:hover {
		cursor: pointer;
	}
}

.flex {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 1rem;
}

.tableRow {
	width: 80%;
}

.tableRowButton {
	width: 20%;
}

.flex > * {
	display: flex;
	flex-direction: row;
}

.container {
	@include layout(row, center, center);
	font-family: $font;
	margin: 5% auto !important;

	label {
		@include layout(row, flex-start, center);
		color: $black;
		font-family: $font;
		padding: 20px 0 0 0;
	}

	.firstLine {
		@include layout(column, flex-start, flex-start);
		width: 100%;
		:global {
			.ant-row {
				width: 100% !important;
			}
		}

		.checkboxInput {
			padding: 50px 0 0 0;
		}

		.customItem {
			margin-right: 1rem;
			margin-bottom: -2px;
		}

		textarea,
		.numberInput {
			border: 0.5px solid #d9d9d9;
			outline: none;
			resize: none;
			box-shadow: none;
			border-radius: 4px !important;
			color: rgb(0, 0, 0) !important;
			width: 100%;
			height: 2.2rem;
			padding: 5px;
			&:hover,
			&:focus,
			&:active {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
			}
		}

		.numberInput {
			padding: 0 !important;
		}

		.customSelect {
			text-align: left;
		}

		textarea::placeholder,
		.numberInput::placeholder {
			opacity: 0.25;
		}
	}

	.secondLine {
		margin-top: 2rem;
		width: 100%;
		@include layout(row, space-between, flex-start);

		.firstColumn {
			@include layout(row, space-between, center);
			width: fit-content;
		}
		.secondColumn {
			@include layout(row, flex-end, center);
		}
	}
}

@media only screen and (max-width: 810px) {
	a,
	a:active,
	a:hover {
		color: $black;
	}

	.container {
		@include layout(row, center, center);
		font-family: $font;
		margin: 5% auto;
		width: 70%;

		label {
			@include layout(row, flex-start, flex-start);
			color: $black;
			font-family: $font;
			padding: 20px 0 0 0;
		}

		.firstLine {
			@include layout(column, flex-start, flex-start);
			width: 100%;
			:global {
				.ant-row {
					width: 100% !important;
				}
			}

			textarea {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
				padding: 5px 5px;
				color: rgb(0, 0, 0) !important;
				margin-bottom: 1rem;
				width: 100%;
				height: 2.2rem;
				&:hover,
				&:focus,
				&:active {
					border: 0.5px solid #d9d9d9;
					outline: none;
					resize: none;
					box-shadow: none;
					border-radius: 4px !important;
				}
			}
		}
		.secondLine {
			margin-top: 1rem;
			width: 100%;
			@include layout(row, center, flex-start);

			.firstColumn {
				width: fit-content;
				@include layout(row, flex-start, center);
			}
			.secondColumn {
				@include layout(row, flex-end, center);
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	a,
	a:active,
	a:hover {
		color: $black;
	}
	.container {
		@include layout(row, center, center);
		font-family: $font;
		margin: 5% auto;
		width: 80%;

		label {
			@include layout(row, flex-start, flex-start);
			color: $black;
			font-family: $font;
			padding: 20px 0 0 0;
		}

		.firstLine {
			@include layout(column, flex-start, flex-start);
			width: 100%;
			:global {
				.ant-row {
					width: 100% !important;
				}
			}

			textarea {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
				padding: 5px 5px;
				color: rgb(0, 0, 0) !important;
				margin-bottom: 1rem;
				width: 100%;
				height: 2.2rem;
				&:hover,
				&:focus,
				&:active {
					border: 0.5px solid #d9d9d9;
					outline: none;
					resize: none;
					box-shadow: none;
					border-radius: 4px !important;
				}
			}
		}

		.secondLine {
			margin-top: 2rem;
			width: 100%;
			@include layout(column, center, center);

			.firstColumn {
				@include layout(row, flex-start, center);
				margin-bottom: 1rem;
				width: fit-content;
			}
			.secondColumn {
				@include layout(row, flex-end, center);
			}
		}
	}
}
