@import "../../main.scss";
@import "../../theme.scss";

.wrapper {
	@include layout(row, center, center);
	font-family: $font;
	.container {
		@include layout(column, flex-start, center);
		box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
		margin-top: 5%;
		width: 25%;
		padding: 2% 0;
		label {
			@include layout(row, flex-start, flex-start);
			color: $black;
			padding: 5% 0 0 0;
		}
	}
}

.deleteContainer {
	margin-top: 2%;
	width: auto;
	font-size: 14px;
	&:hover {
		background-color: #e8e7e7;
	}
}

.fileContainer {
	margin-top: 5%;
	width: 100%;
	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: #e8e7e7;
	}
}

.delete {
	outline: none;
	background-color: transparent;
	border: none;
	float: right;
	&:hover {
		cursor: pointer;
	}
}

.warning {
	font-style: italic;
	font-size: small;
	margin: 5px;
}

@media only screen and (max-width: 1400px) {
	.wrapper {
		@include layout(row, center, center);
		font-family: $font;
		.container {
			@include layout(column, center, center);
			box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
			margin-top: 10%;
			width: 35%;
			padding: 2% 0;
			label {
				@include layout(row, flex-start, flex-start);
				color: $black;
				padding: 5% 0 0 0;
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.wrapper {
		@include layout(row, center, center);
		font-family: $font;
		.container {
			@include layout(column, center, center);
			box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
			margin-top: 10%;
			width: 45%;
			padding: 2% 0;
			label {
				@include layout(row, flex-start, flex-start);
				color: $black;
				padding: 5% 0 0 0;
			}
		}
	}
}

@media only screen and (max-width: 645px) {
	.wrapper {
		@include layout(row, center, center);
		font-family: $font;
		.container {
			@include layout(column, center, center);
			box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
			margin-top: 10%;
			width: 75%;
			padding: 2% 0;
			label {
				@include layout(row, flex-start, flex-start);
				color: $black;
				padding: 5% 0 0 0;
			}
		}
	}
}

:global {
	.ant-row {
		width: 100%;
	}
}
