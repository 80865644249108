td {
	text-align: center;

	.modalBtnContainer {
		display: inline-flex !important;
		margin-right: 3%;
	}

	.modalBtnContainer > div {
		padding-bottom: 0 !important;
	}
}
