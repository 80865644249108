@import "../../main.scss";

.footerContainer {
	@include footer;
	z-index: 100;
	position: sticky;

	.firstline {
		@include layout(row, space-between, center);
		width: 90%;
		height: 80%;
		margin-left: 2%;
		margin-right: 5%;
		margin-top: 2%;
		.firstColumn {
			@include layout(row, flex-start, center);
			width: 30%;
			img {
				width: 100%;
			}
		}
		.secondColumn {
			@include layout(row, space-between, normal);
			width: 70%;
			height: 72%;
			a {
				@include footerText;
				font-size: 1rem;
				padding: 7px 10px;
				text-decoration: none;
				text-align: left;
				white-space: nowrap;
				overflow: hidden;
				.antd-wave-shadow-color {
					color: $white;
				}
				&:hover,
				&:active,
				&:focus {
					font-family: $font;
					text-decoration: none;
					color: $white;
					font-size: 1rem;
					padding: 7px 10px;
				}
			}
			.firstChild {
				width: 50%;
				@include layout(column, flex-start, flex-start);
				h1 {
					font-size: 1.2rem !important;
					font-weight: bold !important;
					padding: 5px 20px !important;
					color: white !important;
				}
				.middle {
					@include layout(row, flex-start, flex-start);
					.first {
						@include layout(column, flex-start, flex-start);
					}
					.second {
						@include layout(column, flex-start, flex-start);
					}
				}
			}
		}
	}
	.secondline {
		@include layout(column, flex-end, flex-end);
		width: 90%;
		height: 25%;
		margin-bottom: 1%;
		a {
			@include footerText;
			padding: 7px 18px;
			text-decoration: none;
			display: inline;

			.antd-wave-shadow-color {
				color: $white;
			}
			&:hover,
			&:active,
			&:focus {
				font-family: $font;
				text-decoration: none;
				color: $white;
				padding: 7px 18px;
			}
		}
		.footerLi {
			padding: 0;
			margin: 0;
			white-space: nowrap;
		}
		ul li {
			font-size: 0.9rem !important;
			list-style-type: disc !important;
			color: white !important;
			display: inline !important;
			margin-right: -15px;
		}
		h1 {
			color: white !important;
			font-size: 1rem;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.footerContainer {
		@include footer;

		.firstline {
			@include layout(row, space-between, center);
			width: 50%;
			height: 70%;
			margin-left: 2%;
			margin-right: 7%;
			margin-top: 2%;
			.firstColumn {
				width: 45%;
				img {
					width: 100%;
				}
			}
			.secondColumn {
				width: 55%;
				height: 72%;
				@include layout(row, space-between, normal);
				.firstChild {
					@include layout(column, flex-start, flex-start);
					h1 {
						font-size: 1.2rem !important;
						font-weight: bold !important;
						padding: 5px 0 !important;
						color: white !important;
					}
					a {
						@include footerText;
						font-size: 0.8rem;
						padding: 7px 5px;
						text-decoration: none;
						.antd-wave-shadow-color {
							color: $white;
						}
						&:hover,
						&:active,
						&:focus {
							font-family: $font;
							text-decoration: none;
							color: $white;
							font-size: 0.8rem;
							padding: 7px 5px;
						}
					}
					.middle {
						@include layout(row, flex-start, flex-start);
						.first {
							@include layout(column, flex-start, flex-start);
						}
						.second {
							@include layout(column, flex-start, flex-start);
						}
					}
				}
			}
		}

		.secondline {
			@include layout(column, flex-end, flex-end);
			width: 94%;
			height: 25%;
			margin-bottom: 1%;
			a {
				@include footerText;
				padding: 7px 18px;
				text-decoration: none;
				.antd-wave-shadow-color {
					color: $white;
				}
				&:hover,
				&:active,
				&:focus {
					font-family: $font;
					text-decoration: none;
					color: $white;
					padding: 7px 18px;
				}
			}
			.footerLi {
				padding: 0;
				margin: 0;
				white-space: nowrap;
			}
			ul li {
				font-size: 0.8rem !important;
				list-style-type: disc !important;
				color: white !important;
				display: inline !important;
				margin-right: -10px;
				a {
					&:hover,
					&:active,
					&:focus {
						font-size: 0.8rem !important;
					}
				}
			}
			h1 {
				color: white !important;
				font-size: 1rem;
			}
		}
	}
}

@media only screen and (max-width: 550px) {
	.footerContainer {
		@include footer;

		.firstline {
			@include layout(row, space-between, center);
			width: 50%;
			height: 75%;
			margin-left: 2%;
			margin-right: 7%;
			margin-top: 2%;
			.firstColumn {
				display: none;
			}
			.secondColumn {
				width: 100%;
				height: 72%;
				@include layout(row, center, normal);
				.firstChild {
					@include layout(column, flex-start, flex-start);
					h1 {
						font-size: 100% !important;
						font-weight: bold !important;
						padding: 5px 0 !important;
						color: white !important;
					}
					.middle {
						@include layout(row, flex-start, flex-start);
						.first {
							@include layout(column, flex-start, flex-start);
						}
						.second {
							@include layout(column, flex-start, flex-start);
							display: flex !important;
						}
					}
					a {
						@include footerText;
						font-size: 1rem;
						padding: 5px 5px;
						text-decoration: none;
						.antd-wave-shadow-color {
							color: $white;
						}
						&:hover,
						&:active,
						&:focus {
							font-family: $font;
							text-decoration: none;
							color: $white;
							font-size: 1rem;
							padding: 5px 5px;
						}
					}
				}
			}
		}

		.secondline {
			@include layout(column, flex-end, center);
			width: 94%;
			height: 25%;
			margin-bottom: 1%;
			a {
				@include footerText;
				padding: 7px 18px;
				text-decoration: none;
				.antd-wave-shadow-color {
					color: $white;
				}
				&:hover,
				&:active,
				&:focus {
					font-family: $font;
					text-decoration: none;
					color: $white;
					padding: 7px 18px;
				}
			}
			.footerLi {
				padding: 0;
				margin: 0;
				white-space: nowrap;
			}
			ul li {
				font-size: 0.8em !important;
				list-style-type: disc !important;
				color: white !important;
				display: inline !important;
			}
			h1 {
				color: white !important;
				font-size: 0.8rem;
			}
		}
	}
}
