@import "../../main.scss";
@import "../../theme.scss";

.firstChild {
	margin: 2% 0;
	font-family: $font;
	p {
		font-weight: bold;
		color: $primaryColor;
		font-size: 1.4rem;
	}
}

.Row {
	height: auto;
	width: 100%;
	.filter {
		margin-top: 100px;
		margin-bottom: 10px;
	}
	.ListItem {
		text-align: left;
	}
}
