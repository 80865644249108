@import "../../main.scss";
@import "../../theme.scss";

.wrapper {
	@include layout(column, flex-start, flex-start);
	width: 100%;
	font-family: $font;
	padding: 5% 0;

	.container {
		@include layout(column, flex-start, flex-start);
		width: 100%;
		padding: 1% 13%;
		h2 {
			color: $primaryColor;
			font-size: 1.5rem;
			font-weight: bold;
			margin-bottom: 1rem;
		}
		.info {
			@include layout(column, flex-start, flex-start);
			width: 100%;
			padding: 1% 5%;
			text-align: left;

			h3 {
				font-size: 1rem;
				font-weight: bold;
				color: $secondColor;
			}
		}
		img {
			width: 100%;
		}
	}
}
