@import "../../../main.scss";
@import "../../../theme.scss";

:global .ant-tabs-tab {
	padding: 0 !important;
}

@media screen and (max-width: 1000px) {
	.displayFlex > div > div > div {
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
		width: 80vw !important;
		flex-direction: column !important;
	}
}

.searchBox {
	margin-top: 24px;
	margin-bottom: 10px;
	width: 100% !important;
}

.filterButton {
	display: flex;
	align-items: center;
}

@media only screen and (min-width: 1101px) {
	.filterButton {
		display: none !important;
	}
}

@media only screen and (max-width: 1100px) {
	.filters,
	.searchBox {
		display: none !important;
	}

	.filterButton {
		padding-left: 5%;
	}
}

:global .ant-list-vertical .ant-list-item-meta {
	margin-bottom: 0 !important;
}

:global .ant-list-vertical .ant-list-item-meta-title {
	margin-bottom: 0 !important;
	font-weight: bold;
}

:global .ant-list-lg .ant-list-item {
	padding: 4px 24px !important;
}
