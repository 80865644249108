@import "../../main.scss";
@import "../../theme.scss";

.usersTable {
	width: 100%;
}

.usersTable > div > div > div > div > div > table > tbody > tr > td {
	text-align: left !important;
}

.usersTable > div > div > div > div > div > table > thead > tr > th {
	text-align: left !important;
}

.modalBtnContainer {
	display: inline-flex !important;
	margin-right: 3%;
}

.ant-table-content .ant-table-container .ant-table {
}
.modalContainer > div {
	padding-bottom: 0 !important;
}

.container {
	@include layout(column, flex-start, center);
	font-family: $font;
	.firstLine {
		@include layout(row, flex-start, flex-start);
		margin: 5% 13.5% 5% 13%;
		width: 90%;
		.firstColumn {
			@include layout(column, flex-start, flex-start);
			background-color: rgb(255, 255, 255);
			height: 100%;
			width: 100%;

			.title,
			.cardTitle {
				font-weight: bold;
				font-size: 1.3rem;
				text-align: left;
				margin: 1% auto 2%;
			}

			.title {
				font-size: 1.5rem;
			}

			.imgContainer {
				margin-left: auto;
				margin-right: auto;
			}

			.image {
				width: 100%;

				img {
					width: 15vw;
					min-height: 13vh;
					border-radius: 5px;
					margin: 0;
					border: #1c3334;
				}

				span {
					@include layout(row, center, flex-end);
					opacity: 0;
					border-radius: 5px;
					padding: 7%;
					margin: -23% 0% 0% 0%;
					width: 15rem;
				}
			}

			.image:hover {
				width: 100%;

				img {
					width: 15vw;
					min-height: 13vh;
					border-radius: 5px;
					margin: 0;
					border: #1c3334;
				}

				span {
					@include layout(row, center, flex-end);
					opacity: 1;
					width: 15rem;
					border-radius: 5px;
					font-weight: bold;
					margin: -23% 0% 0% 0%;
					z-index: 2;
					position: relative;
					padding: 7%;
					font-family: $font;
					color: white;
					background-color: rgba(black, 0.5);
				}
			}

			h4 {
				font-family: $font;
				opacity: 0.9;
				font-weight: bold;
				font-size: 1.3rem;
				margin: auto;
			}

			h5 {
				font-family: $font;
				opacity: 0.9;
				font-weight: bold;
				font-size: 1.2rem;
				margin: auto;
			}
			.companyInfo {
				@include layout(column, flex-start, flex-start);
				margin: 3% auto 0 auto;
				width: 75%;

				.contactInfo {
					@include layout(row, space-between, flex-start);
					width: 100%;
					margin-bottom: 2%;

					a,
					span {
						font-size: 1.2rem;
						margin-right: 0.7rem;
						opacity: 0.8;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}
				.subtitle {
					font-family: $font;
					font-size: 0.9rem;
					opacity: 0.8;
					font-weight: bold;
					margin: 1% 0 1% 0;
				}
			}
		}

		.secondColumn {
			width: 20%;
			@include layout(column, flex-end, center);
		}
	}
}

@media only screen and (max-width: 645px) {
	.container {
		@include layout(column, flex-start, center);
		font-family: $font;
		.firstLine {
			@include layout(column, flex-start, flex-start);
			.firstColumn {
				.title,
				.cardTitle {
					font-weight: bold;
					font-size: 1rem;
					margin-bottom: 3%;
					text-align: left;
				}

				.title {
					font-size: 1.1rem;
				}

				.image {
					width: 100%;
					img {
						width: 10vw;
						min-height: 9vh;
						border-radius: 5px;
						margin: 0;
					}
					span {
						@include layout(row, center, flex-end);
						opacity: 0;
						border-radius: 5px;
						padding: 7%;
						margin: -28% 0% 0% 0%;
						width: 100%;
					}
				}
				.image:hover {
					width: 100%;
					img {
						width: 10vw;
						min-height: 9vh;
						border-radius: 5px;
						margin: 0;
					}
					span {
						@include layout(row, center, flex-end);
						opacity: 1;
						width: 100%;
						border-radius: 5px;
						font-weight: bold;
						margin: -28% 0% 0% 0%;
						z-index: 2;
						position: relative;
						padding: 7%;
						font-family: $font;
						color: white;
						background-color: rgba(black, 0.5);
					}
				}
				.companyInfo {
					@include layout(column, flex-start, flex-start);
					margin: 1% 0 0 0;
					width: 100%;

					.contactInfo {
						@include layout(row, space-between, flex-start);
						font-size: 1rem;
						margin-right: 0.6rem;

						a,
						span {
							opacity: 0.8;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
					.subtitle {
						font-family: $font;
						font-size: 0.8rem;
						opacity: 0.8;
						font-weight: bold;
						margin: 1% 0 1% 0;
					}
				}
			}

			.secondColumn {
				@include layout(column, flex-start, flex-start);
				width: 100%;
				margin: 10% 0;
			}
		}
	}
}
